import Cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import SocialLinks from '../Sidebar/SocialLinks';
import Typography from '@mui/material/Typography';

export default function Footer({ secondCopyright, isNoSpace, isLightFont }) {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const hasCookie = Cookie.get('isApp') === 'true';
    if (hasCookie) setShow(false);
  }, []);

  if (!show) return <div className="safebottom" />;

  return (
    <div className="container underlineLinksOnHover">
      <footer
        className={`pt-4 pt-md-5 ${isNoSpace ? '' : `my-md-5 border-top`}`}
        style={isNoSpace ? { marginTop: -5 } : undefined}
      >
        <div className="row">
          <div className="col-6 col-md">
            <Typography
              gutterBottom
              variant="h6"
              component="h5"
              className={isLightFont ? 'text-light' : undefined}
              color="textPrimary"
            >
              About Us
            </Typography>
            <ul className="list-unstyled text-small">
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/faq"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/blog"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/newsletter"
                >
                  Newsletter
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/join"
                >
                  Start Your Own Travel Blog
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/app"
                >
                  Get our App
                </Link>
              </li>
              <li>
                <a
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="https://www.menutranslatorapp.com/"
                  target="_blank"
                >
                  Menu Translator App
                </a>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/how-to-make-money-with-a-travel-blog"
                >
                  Monetization
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/help"
                >
                  Help Center
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/ai"
                >
                  AI Blogger
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/chat"
                >
                  AI Travel Planner
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/tools/travel-blog-name-ideas-generator"
                >
                  Travel Blog Name Ideas Generator
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/blog/wordpress-vs-travelfeed-the-best-choice-for-travel-bloggers-2023"
                >
                  TravelFeed vs WordPress
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <Typography
              gutterBottom
              variant="h6"
              component="h5"
              className={isLightFont ? 'text-light' : undefined}
              color="textPrimary"
            >
              Contact Us
            </Typography>
            <ul className="list-unstyled text-small">
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/contact?press=true"
                >
                  Press
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/contact?support=true"
                >
                  Support
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/contact?advertising=true"
                >
                  Advertise with Us
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/contact?content=true"
                >
                  Content and DMCA
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/contact"
                >
                  General Inquiries
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <Typography
              gutterBottom
              variant="h6"
              component="h5"
              className={isLightFont ? 'text-light' : undefined}
              color="textPrimary"
            >
              Legal
            </Typography>
            <ul className="list-unstyled text-small">
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/terms"
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/privacy"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/cookies"
                >
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link
                  className={isLightFont ? 'text-light' : 'text-muted'}
                  href="/about/affiliate-disclosure"
                >
                  Affiliate Disclosure
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <SocialLinks alignLeft isLight={isLightFont} />
            <small
              className={`pl-3 d-block ${
                isLightFont ? 'text-light' : 'text-muted'
              }`}
            >
              &copy; {new Date().getFullYear()} TravelFeed - a Hive frontend.
              All rights reserved.
            </small>
            {secondCopyright && (
              <small
                className={`pl-3 d-block ${
                  isLightFont ? 'text-light' : 'text-muted'
                }`}
              >
                {secondCopyright}
              </small>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}
